<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="项目">
          <el-select clearable style="width: 120px" filterable v-model="searchData.project_id" placeholder="项目">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTask()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="success" @click.stop="clickCreate()">添加核销</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="project_name" label="商家"/>
      <el-table-column prop="task_name" label="任务名"/>
      <el-table-column label="进度">
        <template #default="scope">
          {{scope.row.complete_num}} / {{scope.row.task_num}}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <el-switch
              :model-value="scope.row.status"
              :active-value="1"
              :inactive-value="2"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="range_time" label="发送时段" />
      <el-table-column prop="space" label="间隔时长"/>
      <el-table-column prop="wechat_id" label="关联消息ID"/>
      <el-table-column prop="send_at" label="最近核销时间"/>
      <el-table-column prop="next_at" label="下次发送时间"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="info" size="small" @click="clickLink(scope.row)">链接</el-button>
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTask()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <el-dialog v-model="link.show" title="链接" append-to-body>
      <div>
        <el-input v-model="link.link"></el-input>
      </div>
    </el-dialog>

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="核销链接" fullscreen append-to-body>
      <el-form
          ref="merchant"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="项目" filterable prop="project_id">
          <el-select v-model="formData.data.project_id" filterable>
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :label="'(' +item.id + ') ' + item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务" filterable prop="task_id">
          <el-select v-model="formData.data.task_id" filterable>
            <el-option
                v-for="(item, key) in tasks"
                :key="key"
                :label="'(' +item.id + ') ' + item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送时段" prop="range_time">
          <el-time-picker
              v-model="formData.data.range_time"
              arrow-control
              editable
              is-range
              value-format="HHmm"
              format="HH:mm"
              range-separator="至"
          />
        </el-form-item>
        <el-form-item label="发送间隔" prop="space">
          <el-input v-model="formData.data.space" type="number" placeholder="单位分钟"/>
        </el-form-item>

        <el-form-item label="消息ID" prop="wechat_id">
          <el-input v-model="formData.data.wechat_id" type="number" placeholder="微信群消息ID"/>
        </el-form-item>

        <el-form-item label="强制提交" prop="force">
          <el-switch
              v-model="formData.data.force"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end -------->

  </div>
</template>

<script>
import { apiGetProjects } from '@/api/projectApi'
import {apiGetTaskProjectIndex, apiGetTaskIndex, apiStoreTaskProject, apiUpdateTaskProject, apiDisableTaskProject} from "@/api/taskApi"
import {getCompanyId} from "@/plugins/company"

let formData = {
  id: 0,
  project_id: '',
  range_time: ['1200', '2000'],
  task_id: '',
  space: 60,
  wechat_id: 0,
  force: 0,
}

export default {
  created() {
    this.getProjects()
    this.getTask()
    this.getTaskProject()
  },
  data() {
    return {
      link:{
        show: false,
        id: '',
        password: '',
      },
      searchData:{
        project_id: '',
        status: 1,
        page: 1,
        per_page: 15,
        company_id: getCompanyId(),
      },
      pageData:{
        total: 0
      },
      projects:[],
      tasks: [],
      tableData:[],
      // 创建表单
      formData:{
        lock: false,
        data: formData,
        rules: {
          project_id: [{
            required: true,
            message: '请选择项目',
            trigger: 'blur',
          }],
          task_id: [{
            required: true,
            message: '请选择任务',
            trigger: 'blur',
          }],
          range_time: [{
            required: true,
            message: '请填写金额',
            trigger: 'blur',
          }],
          space: [{
            required: true,
            message: '请填写间隔时间',
            trigger: 'blur',
          }],
          force: [{
            required: true,
            message: '请填写间隔时间',
            trigger: 'blur',
          }]
        },
        show: false,
      },
    }
  },
  methods:{
    getTaskProject () {
      apiGetTaskProjectIndex(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    },
    getTask () {
      apiGetTaskIndex({per_page: 200, status: 1}).then(res =>{
        this.tasks = res.data
      })
    },
    getProjects() {
      apiGetProjects({page: 1, per_page: 500, company_id: getCompanyId(),}).then((res) => {
        this.projects = res.data
      })
    },
    clickLink (row) {
      this.link.link = '/task/sale/'+ row.id +'/'+ row.password
      this.link.show = true
    },
    clickCreate () {
      this.formData.data = formData
      this.formData.show = true
    },
    clickUpdate (item) {
      item.range_time = item.range_time.split('~')
      this.formData.data = item
      this.formData.show = true
    },
    closeDialog () {
      this.formData.show = false
    },
    onSubmit() {
      let vue = this
      this.$refs['merchant'].validate((valid) =>{
        if(valid) {

          if (vue.formData.data.id) {
            apiUpdateTaskProject(this.formData.data.id, this.formData.data).then(() =>{
              vue.$message.success('操作成功')
              vue.getTaskProject()
              this.formData.show = false
            })
          } else {
            apiStoreTaskProject(this.formData.data).then(() =>{
              vue.$message.success('操作成功')
              vue.getTaskProject()
              this.formData.show = false
            })
          }

        }
      })
    },
    disableTask (item) {
      apiDisableTaskProject(item.id).then(() =>{

      })
    }
  }
}
</script>
